.breadcrumb-item{
  //font-weight: bold;
}
.vertical-center {
  display: flex;
  align-items: center;
}

.loader{
  position: fixed;
  margin: auto;
  top: calc(50% - 58.23px);
  left: 50%;
  z-index: 9999;
}

.content{
  padding-bottom: 15px !important;
}