.rdt_TableHeader{
  width: unset !important;
}
.grid_height_fix {
  min-height: 500px;
}
.new-application-btn{
  display: unset;
}

@media (max-width: 576px) {
  .new-application-btn{
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
