@mixin btn($light, $dark){
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: white;
  &:visited {
    color: white;
  }
  background-image: linear-gradient($light, $dark);
  border: 1px solid $dark;
  &:hover {
    background-image: linear-gradient($light, $dark);
    &[disabled] {
      background-image: linear-gradient($light, $dark);
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
.wizard{
  .pageList {
    border-left: 3px solid #00AB4E;

    & .currentPage{
      color: #FC9340;
    }
    & li.currentPage div:before {
      background: #edc948;
    }
  }

  h1 {
    text-align: center;
    color: #222;
  }

  h2 {
    text-align: center;
    color: #222;
  }

  a {
    display: block;
    text-align: center;
    color: #222;
    margin-bottom: 10px;
  }

  .wizard_body{
    margin-top: 0.5rem  !important;
  }

  form {
    max-width: 1500px;
    padding-bottom: 20px;
    border-radius: 3px;
    position: relative;

    & > .buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 15px;
    }

    .buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .error {
      display: flex;
      font-weight: bold;
      color: #800;
      flex-flow: row nowrap;
      justify-content: center;
    }
    pre {
      position: relative;
      border: 1px solid #ccc;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }

  }
  button {
    margin: 0 10px;
    &[type='submit'] {
      @include btn(#01d25d, #00AB4E);
    }
    &.back {
      @include btn(#e27c79, #c9302c);
    }
  }

  .dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile_wizard{
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .mobile_wizard{
    flex-wrap: wrap;
    padding-bottom: 15px;

    .MuiStepConnector-root{
      display: none;
    }

    .MuiStepLabel-labelContainer{
      display: none;
    }

  }

  .wizard{
    form{
      padding: 0 !important;
    }

    .wizard_body{
      padding: 0 !important;
    }

  }

}
